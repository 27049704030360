import * as React from 'react'
import { useEffect } from 'react';
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import { TopMain } from '@components/index'
import { FeedInFunc } from '@func/index'
import LogoBrandBg from "@svgs/logo_brand_bg.svg"
import LogoLinkBg from "@svgs/logo_odawara.svg"
// import TopTable from "@svgs/top_table.svg"

const IndexPage = () => {

  useEffect(() => {
    FeedInFunc();
  }, []);

  return (
    <main className='mainTop'>
      {/* CONTENTS */}


      <TopMain />


      <section className='sec-info'>

        <div className="info-2-wrap">
          <div className="bg-logo bg-logo1"><LogoBrandBg alt="" /></div>
          <div className="bg-logo bg-logo2"><LogoBrandBg alt="" /></div>
          <div className="c-content">

            <div className="info-box">
              <p className="p1">【第一期45戸即日申込完売】</p>
              {/* <p className="band">日時</p> */}
              {/* <p className="p2">2022年<span>4</span>月<span>29</span>日(金・祝)〜2022年<span>5</span>月<span>8</span>日(日)</p> */}
              <p className="p3">7/2(土)より第二期登録申込受付開始。<br/>モデルルームご来場予約受付中<span>（予約制）</span></p>

              {/* <div className="table-box">
                <TopTable />
                <p className="note">※掲載の予約状況は4月8日時点のものであり、今後変更となる場合がございます。</p>
              </div> */}


              <div className="flex-box">
                <a href="https://www.haseko-sumai.com/asp/syuto/sinki/n-odawara/request.asp" target="_blank" rel="noopener noreferrer" className='btn btn-request'>
                  <span>資料請求はこちら</span>
                </a>
                <a href="https://airrsv.net/AKR0034741846/calendar" target="_blank" rel="noopener noreferrer" className='btn btn-reserve'>
                  <span>来場予約はこちら</span>
                </a>
              </div>
            </div>

          </div>
        </div>

      </section>


      <section className='sec-link'>

        <div className="link-wrap">

          <div className="link-box">
            <div className="img-box">
              <div className="img">
                <StaticImage src="../assets/images/top/img-concept.jpg" alt="" />
              </div>
              {/* <p className="c-cap"></p> */}
            </div>
            <div className="txt-box">
              <div className="bg-logo"><LogoLinkBg alt="" /></div>
              <p className="ttl">コンセプト</p>
              <p className="subttl">CONCEPT</p>
              <p className="lead">進化する小田原、<br />その中心に象徴となる<br className='_pc' />住まいを。</p>
              <Link to="/concept/" className='btn'><span>CONCEPT</span></Link>
            </div>
          </div>

          <div className="link-box reverse">
            <div className="img-box">
              <div className="img">
                <StaticImage src="../assets/images/top/img-attractiveness.jpg" alt="" />
              </div>
              <p className="note">1.小田原駅東口・ミナカ小田原（約340m／徒歩5分） <br className='_sp' />2.小田原銀座商店街（約10m／徒歩1分）</p>
            </div>
            <div className="txt-box">
              <div className="bg-logo"><LogoLinkBg alt="" /></div>
              <p className="ttl">東口の魅力</p>
              <p className="subttl">ATTRACTIVENESS</p>
              <p className="lead">暮らしの豊かさを<br className='_pc' />支えつづけてきた、<br />小田原市の中心地。</p>
              <Link to="/attractiveness/" className='btn'><span>ATTRACTIVENESS</span></Link>
            </div>
          </div>

          <div className="link-box">
            <div className="img-box">
              <div className="img">
                <StaticImage src="../assets/images/top/img-access.jpg" alt="" />
              </div>
              <p className="c-cap">image photo</p>
            </div>
            <div className="txt-box">
              <div className="bg-logo"><LogoLinkBg alt="" /></div>
              <p className="ttl">アクセス</p>
              <p className="subttl">ACCESS</p>
              <p className="lead">電車もマイカーも、<br />ストレスフリーな<br className='_pc' />交通ネットワーク。</p>
              <Link to="/access/" className='btn'><span>ACCESS</span></Link>
            </div>
          </div>

          <div className="link-box reverse">
            <div className="img-box">
              <div className="img">
                <StaticImage src="../assets/images/top/img-design.jpg" alt="" />
              </div>
              <p className="c-cap">エントランス完成予想CG</p>
            </div>
            <div className="txt-box">
              <div className="bg-logo"><LogoLinkBg alt="" /></div>
              <p className="ttl">デザイン</p>
              <p className="subttl">DESIGN</p>
              <p className="lead">城下町の<br className='_pc' />風情を感じる<br />小田原の邸宅。</p>
              <Link to="/design/" className='btn'><span>DESIGN</span></Link>
            </div>
          </div>

          <div className="other-link-box">
            <Link to="/landplan/" className='link'>
              <div className="img">
                <StaticImage src="../assets/images/top/img-landplan.jpg" alt="" />
              </div>
              <div className="txt-box">
                <p className="ttl">ランドプラン</p>
                <p className="subttl">LAND PLAN</p>
              </div>
              <p className="c-cap">自主管理広場完成予想イラスト</p>
            </Link>

            <Link to="/plan/" className='link'>
              <div className="img">
                <StaticImage src="../assets/images/top/img-plan.jpg" alt="" />
              </div>
              <div className="txt-box">
                <p className="ttl">間取り</p>
                <p className="subttl">PLAN</p>
              </div>
              <p className="c-cap">image photo</p>
            </Link>

            <Link to="/quality/" className='link'>
              <div className="img">
                <StaticImage src="../assets/images/top/img-quality.jpg" alt="" />
              </div>
              <div className="txt-box">
                <p className="ttl">設備仕様</p>
                <p className="subttl">QUALITY</p>
              </div>
              <p className="c-cap">キッチン</p>
            </Link>

            <Link to="/modelroom/" className='link'>
              <div className="img">
                <StaticImage src="../assets/images/top/img-modelroom.jpg" alt="" />
              </div>
              <div className="txt-box">
                <p className="ttl">モデルルーム</p>
                <p className="subttl">MODEL ROOM</p>
              </div>
              <p className="c-cap">リビングダイニングキッチン</p>
            </Link>

            <Link to="/company/" className='link'>
              <div className="img">
                <StaticImage src="../assets/images/top/img-company.jpg" alt="" />
              </div>
              <div className="txt-box">
                <p className="ttl">会社紹介</p>
                <p className="subttl">BRAND</p>
              </div>
              <p className="c-cap">image photo</p>
            </Link>
          </div>

        </div>


        <div className="c-content">
          <div className="com-note-wrap">
            <p className="c-note">※掲載の環境写真は、2021年10月に撮影したものです。<br />※掲載の距離表示については地図上の概測距離を、徒歩分数表示については80m＝1分として算出し、端数を切り上げしたものです。<br />※掲載の情報は 2022年1月時点のものです。<br />※掲載の完成予想CGは、計画段階の図面を基に描いたもので、外観・外構・植栽・仕上げ等は実際とは異なる場合があります。外観形状の細部や設備機器等は表現されておりません。またタイルや各種部材の質感や色等は実際とは異なります。行政指導及び施工上の理由等のため、今後変更となる場合があります。敷地内の植栽は育成に必要な環境の下で、竣工から一定期間を経た状態のものを想定して描いており、特定の季節を表したものではありません 。建設地周辺の建物や電柱等については、白線等で表現している部分がございます。周辺環境や景観は将来に渡り保証されるものではありません。予めご了承ください。<br />※「image photo」の表記がある写真は全てイメージです。</p>
          </div>
        </div>

      </section>




      {/* CONTENTS */}
    </main>
  )
}

export default IndexPage
